.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



.shimmer {
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;

  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.currencies-for-dep {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.currencies-for-dep.no .coins-item {
  cursor: wait;
}

.currencies-for-dep .coins-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 80px;
  min-height: 80px;
  border: 1px solid #dedede;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;
}

.currencies-for-dep .coins-item.active {
  background: #dbdbdb !important;
  border-color: #dedede !important;
  cursor: not-allowed;
}

.currencies-for-dep .coins-item:hover {
  border-color: #13b313;
  background: #f5f5f5;
}

.currencies-for-dep .coins-item img {
  width: 40px;
  height: 40px;
}

.margin-top-100 {
  margin-top: 100px;
}

@media screen and (max-width: 991px) {
  .margin-top-100 {
    margin-top: 0;
  }
}

#coinmarketcap-widget-coin-price-block #coinPriceBlock-container {
  display: flex !important;
  overflow: hidden !important;
  flex-wrap: wrap !important;
  gap: 10px 0 !important;

}

.hero-chart.hero__chart.home::before {
  display: none;
}

.hero.header__hero.home {
  margin-top: -100px;
}